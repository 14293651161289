import styled from 'styled-components';

const Wrapper = styled.header`
  padding: 0 var(--globalPaddingLr);
  margin: auto;
  //width: 100%;
  position: sticky;
  top: 0;
  @media (max-width: 860px) {
    //top: -66px;
  }
  z-index: 1000;
  background-color: #fff;
`;

const Container = styled.div`
  margin: auto;
  max-width: var(--globalContainer);
  padding: var(--globalPaddingLr) 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid var(--dividerColor);
`;

const Nav = styled.nav`
  @media (max-width: 860px) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-basis: auto;
    flex-direction: row;
    order: 2;
    align-items: right;
  }
`;

const NavList = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  order: 3;
  gap: 2rem;

  & li a {
    color: var(--headingsColor);
    transition: color 0.1s linear;
    font-weight: 600;
    &:hover {
      color: var(--primaryColor);
    }
  }

  @media (max-width: 860px) {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    order: 3;
    gap: 1rem;
    padding-top: 1.5rem;
  }
`;

const Right = styled.div`
  display: flex;
  grid-auto-flow: column;
  column-gap: var(--gapRegular);
`;

const VerticalDivider = styled.span`
  height: 100%;
  width: var(--borderSmall);
  background: var(--dividerColor);
  display: none;

  @media (min-width: 768px) {
    display: none;
  }
`;

export { Wrapper, Container, Nav, NavList, Right, VerticalDivider };
