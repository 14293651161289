/* eslint-disable jsx-a11y/iframe-has-title */
import { graphql, useStaticQuery } from 'gatsby';
import { usePageLocale } from '../../../hooks/usePageLocale';
import { Wrapper, Container, Map, BottomLine } from './styles';
import Contacts from '../../contacts';

const GOOGLE_MAP_KEY = 'AIzaSyCPO5r3cp5nnm9WECMmvjvHnvKAwtzFCa4' // process.env.GATSBY_EMBEDED_GOOGLE_MAP_KEY;

// TODO manage API key access (only remote websites needs it)
// TODO probably better to get it form the CMS, think about it.
let iframeTitles = {
  'cz': 'Mapa Google zobrazující polohu zubní kliniky Doktor Dent na hranicích Německa a České republiky v centru města Cheb, Karlovarský kraj.',
  'uk': 'Мапа Google яка показує розташування стоматологічної клініки Doktor Dent у центрі міста Хеб (Егер), Карловарський Край',
  'ru': 'Карта Google, показывающая местоположение стоматологической клиники "Доктор Дент" в центре города Хебе (Егер), Карловарский край'
}

export const Footer = () => {
  const { pageLocale, pageLanguage } = usePageLocale();

  const urlSearchParams = {
    region: 'CZ',
    language: pageLanguage,
    zoom: ['ru', 'de'].includes(pageLocale) ? 7 : 18,
    q: 'place_id:ChIJFzEOX5n2oEcRqchrG2CGat0',
    key: GOOGLE_MAP_KEY
  }

  const urlSearchParamsString = (new URLSearchParams(urlSearchParams)).toString()

  return (
    <Wrapper>
      <Container>
        <Map className="iframe">
          <iframe
            title={ iframeTitles[ pageLocale ] ? iframeTitles[ pageLocale ] : iframeTitles.cz}
            width="1600"
            height="900"
            frameBorder="0"
            loading="lazy"
            allowFullScreen
            src={`https://www.google.com/maps/embed/v1/place?${urlSearchParamsString}`}
          ></iframe>
        </Map>
        <Contacts anchor={true}/>
        <BottomLine/>
      </Container>
    </Wrapper>
  );
};
