import { createContext, useState } from 'react';

const LocaleContext = createContext({});

// Language map took from https://developers.google.com/maps/documentation/javascript/localization
const languageMap = {
  uk: 'uk',
  ru: 'ru',
  de: 'de',
  cz: 'cs',
};

const LocaleProvider = ({ children, pageData }) => {
  const { locale, id } = pageData;
  const [pageLocale] = useState(locale);

  const [pageLanguage] = useState(
    languageMap[locale] ? languageMap[locale] : locale
  );

  return (
    <LocaleContext.Provider
      value={{
        pageLocale,
        id,
        pageLanguage,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

export { LocaleContext, LocaleProvider };
