import styled, { css } from 'styled-components';

const Wrapper = styled.section`
  width: 100%;
  display: grid;
  padding: var(--globalPaddingTb) var(--globalPaddingLr);
  margin: 5vh auto;
  row-gap: var(--gapXL);
  max-width: var(--globalContainer);
  align-items: center;

  ${({ isFullViewport }) =>
    isFullViewport &&
    css`
      justify-content: center;
      height: 100vh;

      & > div {
        justify-items: center;
        text-align: center;
      }
    `};

  @media (max-width: 768px) {
    margin: 0vh auto;
  }
`;

const TextBox = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: var(--gapRegular);
  width: 700px;
  height: max-content;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Caption = styled.p`
  margin-bottom: 1vh;
  color: white;
  font-size: var(--headingM);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: var(--headingsLineHeight);
  font-weight: 400;

  @media (max-width: 768px) {
    color: white;
    width: fit-content;
    block-size: fit-content;
    padding: 0.1rem 0.3rem;
    border-radius: 0.1rem;
  }
`;

const Title = styled.h1`
  margin-bottom: 2vh;
  font-size: var(--headingXXL);
  color: white;
  line-height: var(--headingsLineHeight);

  @media (max-width: 768px) {
    word-wrap: break-word;
    margin-bottom: 5vh;
    font-size: var(--headingXXL);
    width: fit-content;
    block-size: fit-content;
    padding: 0.1rem 0.3rem;
    border-radius: 0.1rem;
  }

  @media (max-width: 400px) {
    font-size: var(--headingXL);
  }

  @media (max-width: 280px) {
    font-size: var(--headingL);
  }
`;

const Subtitle = styled.h2`
  font-size: var(--headingM);
  font-weight: normal;
  margin-bottom: 5vh;
  color: white;
  line-height: var(--headingsLineHeight);

  @media (max-width: 768px) {
    width: fit-content;
    block-size: fit-content;
    padding: 0.1rem 0.3rem;
    border-radius: 0.1rem;
  }
`;

export { Wrapper, TextBox, Caption, Title, Subtitle };
