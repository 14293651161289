import styled from 'styled-components';

import { Wrapper, TextBox, Caption, Title, Subtitle } from './Layout/Hero/styles';

export const Hero = ({
  caption,
  title,
  subtitle,
  button,
  sectionChildren,
  isFullViewport,
}) => (
  <Wrapper isFullViewport={isFullViewport}>
    <TextBox>
      {caption && <Caption>{caption}</Caption>}
      <Title as="h2">{title}</Title>
      <Subtitle as="h3">{subtitle}</Subtitle>
      {button}
    </TextBox>
    {sectionChildren}
  </Wrapper>
);


const SellingPoints = ({features}) => {
  return (
    <Section>
      {features.map(({id, title, description}) => (
        <Hero
          key={id}
          title={title}
          subtitle={description}
        />
      ))}
    </Section>
  );
};

const Section = styled.div`
  margin: 4vh auto 4vh;
`;

export default SellingPoints;
