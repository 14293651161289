import { useFormattedDate } from '../../../../hooks/useFormattedDate';
import styled from 'styled-components';

import {
  CardLink,
  CategoryBox,
  ContentOverlay,
  ContentWrapper,
  PostTitle,
  Date,
  Excerpt,
  AuthorCtaContainer,
  AuthorContainer,
  AuthorImg,
  artDirectedCardImgs,
  CardImgContainer,
  CardImg,
} from './styles';

const CardLinkNonClickable = (styled.div``).withComponent('div');

export const ArticleCard = ({
  recordId,
  date,
  title,
  excerpt,
  authorImg,
  authorName,
  category,
  cardImg,
  cardImgMobile,
  altImg,
}) => {
  const images = artDirectedCardImgs(cardImg, cardImgMobile);

  const { formattedDate } = useFormattedDate(date);

  return (
    <article>
      <CardLink recordId={recordId} >
        <CardImgContainer>
          <CardImg objectFit="cover" image={images} alt={altImg || ''} />
        </CardImgContainer>
        <ContentOverlay>
          <ContentWrapper>

            <PostTitle>{title}</PostTitle>
          </ContentWrapper>
        </ContentOverlay>
      </CardLink>
    </article>
  );
};
