import { useMediaQuery } from 'react-responsive';
import Vimeo from '@u-wave/react-vimeo';
import { useEffect, useState } from 'react' 
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"


export const query = graphql`
  query {
    file(relativePath: {regex: "/.*background-video-fallback.png.*/"}) {
      publicURL
      childImageSharp {
        fluid {
          base64
          tracedSVG
          srcWebp
          srcSetWebp
          originalImg
          originalName
        }
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

// TODO: make it responsive 
const BackgroundVideoFallbackImage = () => {
  const data = useStaticQuery(query)
  const image = getImage(data.file.childImageSharp.gatsbyImageData)

  return (
    <div>
       <GatsbyImage image={image}/>
    </div>
  )
}

const BackgroundVideo = () => {
  const [isNarrow, setNarrow] = useState(
    window.innerHeight > window.innerWidth
  )

  const [ errorOccured, setErrorOccured ] = useState(null)

  useEffect(() => {
    const handleRotation = () => {

      setNarrow(
        window.innerHeight > window.innerWidth
      )
    }

    window.addEventListener('resize', handleRotation)
    return () => {
      window.removeEventListener('resize', handleRotation)
    }
  }, [])



  const isMobile = useMediaQuery({ query: '(max-width: 400px)'})
  // if height is > 1024 make video block height bigger
  const isHigh = useMediaQuery({ query: '(min-height: 1024px)'})

  let videoHeight = isMobile ? 34 : isHigh ? 62 : 40;
  let rem = parseInt(getComputedStyle(document.documentElement).fontSize);;
  const heightPx = videoHeight  * rem;

  return (
    <>
    {
      !errorOccured ? (
        <Vimeo
          video="824820216"
          muted={true}
          background={true}
          loop={true}
          height={`${heightPx}px`}
          responsive={isNarrow ? false : true}
          dnt={true}
          style={
            isNarrow
              ? {
                  position: 'absolute',
                  left: '38%',
                  transform: 'translateX(-38%)',
                }
              : {}
          }
          onError={(err) => setErrorOccured(true)}
        />
      ) : (
        <BackgroundVideoFallbackImage data={query} />
      )
    }
    </>
  );
};

export default BackgroundVideo;
