import styled from 'styled-components';

const Wrapper = styled.footer`
  width: var(--globalContainer);
  width: 100%;
  max-width: var(--globalContainer);
  margin: auto;
  padding: 0;

  @media (max-width: 950px) {
    padding: 0;
  }
`;

const Container = styled.div`
  border-top: 1px solid var(--dividerColor);
  width: 100%;
  @media (max-width: 620px) {
    justify-items: center;
  }
`;

const Map = styled.div`
  margin: 0 auto;
  border: 0;
  &.iframe {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 400px;
  }

  & iframe {
    overflow: hidden;
    border: 0;
    align-self: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const BottomLine = styled.div`
  margin-top: 2em;
  display: flex;
  background-color: white;
  height: 35px;
  border-top: 1px solid var(--dividerColor);
`;

export { Wrapper, Container, Map, BottomLine };
