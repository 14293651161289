import React from 'react';
import styled from 'styled-components';
import { SectionGridTwoCols, GridTextBox } from './Layout/sharedStyles/sectionStyles';
import { RichText } from './Layout/RichText/index';
import { graphql, useStaticQuery } from 'gatsby';
import { usePageLocale } from '../hooks/usePageLocale';

const Section = styled.section`
    padding: 0 0 1rem;
`

const TextWithNewLines = ({ children }) => {
  // Convert the children into a string
  const text = React.Children.toArray(children).join('');

    // Split the text into lines
    const lines = text.split('\n');
  
    // Convert each line into a JSX element
    const formattedText = lines.map((line, index) => (
      <RichText key={index}>
        {line}
        <br />
      </RichText>
    ));
  
    return <p>{formattedText}</p>;
  };

const Contacts = ({ anchor }) => {
    const data = useStaticQuery(graphql`
        query {
        allDatoCmsFooter {
            nodes {
            id: originalId
            locale
            addressHeading
            addressLine
            addressCta {
                ctaValue
                ctaType
                ctaText
            }
            workingHoursHeading
            workingHoursTimetable
            contactHeading
            contactCtaSecondary {
                ctaText
                ctaType
                ctaValue
            }
            contactCtaPrimary {
                ctaText
                ctaType
                ctaValue
            }
            }
        }
        }
    `);

  const { pageLocale, pageLanguage } = usePageLocale();

  const mapZoom = ['ru', 'de'].includes(pageLocale) ? 7 : 18;

  const {
    allDatoCmsFooter: { nodes },
  } = data;

  const localisedNode = nodes.find(({ locale }) => locale === pageLocale )
    if (!Object.keys(localisedNode).length) return null;
    return (
        <Section>
            <SectionGridTwoCols id={ anchor ? 'contacts' : null}>
            <GridTextBox small>
                <Heading>{localisedNode.addressHeading}</Heading>
                <TextWithNewLines>{localisedNode.addressLine}</TextWithNewLines>
                { localisedNode.addressCta.length > 0 ? <a
                className={ anchor ? "classicButton" : "classicButton classicButtonOutline"}
                href={localisedNode.addressCta[0].ctaValue}
                style={{ margin: 'auto' }}
                target="_blank"
                rel="noreferrer"
                >
                {localisedNode.addressCta[0].ctaText}
                </a> : null }
                
            </GridTextBox>
            <GridTextBox small >
                <Heading>{localisedNode.workingHoursHeading}</Heading>
                <TextWithNewLines>{localisedNode.workingHoursTimetable}</TextWithNewLines>
                <Heading>{localisedNode.contactHeading}</Heading>
                { localisedNode.contactCtaPrimary.length > 0 ? <RichText>
                <a href={`tel:${localisedNode.contactCtaPrimary[0].ctaValue}`}>{localisedNode.contactCtaPrimary[0].ctaText}</a>
                </RichText> : null }
                { localisedNode.contactCtaSecondary.length > 0 ? <RichText>
                <a href={`mailto:${localisedNode.contactCtaSecondary[0].ctaValue}`}>{localisedNode.contactCtaSecondary[0].ctaText}</a>
                </RichText> : null }
            </GridTextBox>
            </SectionGridTwoCols>
        </Section>
    )
};

export default Contacts;

const Heading = styled.h4`
  margin: 0 auto;
  font-size: 1.5rem;
`;