import { ParallaxProvider } from 'react-scroll-parallax';

import { PageHead } from '../../Head/PageHead';
import { Header } from '../Header/Full';
import { Footer } from '../Footer';
import { LocaleProvider } from '../../ContextProviders/LocaleProvider';

import { useEffect } from 'react';
import { useLocation } from '@reach/router';
import { useParallaxController } from 'react-scroll-parallax';

function useUpdateControllerOnRouteChange() {
  const location = useLocation();
  const parallaxController = useParallaxController();

  useEffect(() => {
    parallaxController.update();
  }, [location.pathname]);
}

const ParallaxRouteUpdate = () => {
  useUpdateControllerOnRouteChange();
  return null;
};

export const PageWrapper = ({
  pageData,
  seoTitle,
  seoDescription,
  seoImage,
  seoChildren,
  children,
}) => (
  <ParallaxProvider>
    <ParallaxRouteUpdate />
    <LocaleProvider pageData={pageData}>
      <PageHead
        seoTitle={seoTitle}
        seoDescription={seoDescription}
        seoImage={seoImage}
        children={seoChildren}
      />
      <Header />
      <main>{children}</main>
      <Footer />
    </LocaleProvider>
  </ParallaxProvider>
);
