import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { Parallax } from 'react-scroll-parallax';
import {
  Box,
  ButtonList,
  Flex,
  Heading,
  Kicker,
  Section,
  Subhead,
  Text,
} from './ui';

import styled, { css } from 'styled-components';

const Wrapper = styled.section`
  width: 100%;
  display: grid;
  margin: auto;
  row-gap: var(--gapXL);
  align-items: center;
  background-color: white;

  img {
    max-width: 100%;
  }

  ${({ isFullViewport }) =>
    isFullViewport &&
    css`
      justify-content: center;
      height: 100vh;

      & > div {
        justify-items: center;
        text-align: center;
      }
    `};
`;

const Container = styled.div`
  margin: auto;
  @media (min-width: 860px) {
    max-width: var(--globalContainer);
  }
  padding: var(--globalPaddingLr) 0 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
`;

const Description = styled.div`
  p {
    padding-bottom: 1.5vh;
  }
`;

export default function Hero(props) {
  return (
    <Wrapper>
      <Section>
        <Container>
          {/* <Parallax translateY={[0, 0]}> */}
            <Flex gap={4} variant="responsive">
              <Box width="half">
                {props.image && (
                  <GatsbyImage
                    alt={props.image.alt}
                    image={getImage(props.image.gatsbyImageData)}
                  />
                )}
              </Box>
              <Box width="half" style={{ padding: '0 1em' }}>
                <Heading as="h2">
                  {props.kicker && <Kicker>{props.kicker}</Kicker>}
                  {props.h1}
                </Heading>
                <Subhead as="h3">{props.subhead}</Subhead>
                <Description dangerouslySetInnerHTML={{ __html: props.text }} />
                <ButtonList links={props.links} />
              </Box>
            </Flex>
          {/* </Parallax> */}
        </Container>
      </Section>
    </Wrapper>
  );
}

export const query = graphql`
  fragment HomepageHeroContent on HomepageHero {
    id
    kicker
    h1: heading
    subhead
    text
    links {
      id
      href
      text
    }
    image {
      id
      gatsbyImageData(
        width: 600
        height: 700
        layout: CONSTRAINED
      )
      alt
    }
  }
`;
