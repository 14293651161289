import { SectionGridThreeColsGallery } from './Layout/sharedStyles/sectionStyles';
import { SectionTitle } from './Layout/sharedStyles/headingStyles';
import { ArticleCard } from './Layout/Blog/Cards/ArticleCard';
import styled from 'styled-components';

const Services = ({postNodes, featuredPostsTitle}) => {
  return (
    postNodes.length > 0 && (
        <Section id='services'>
          <SectionTitle>{featuredPostsTitle}</SectionTitle>
          <SectionGridThreeColsGallery>
            {postNodes.map(
              ({
                id: recordId,
                meta: { updatedAt },
                title,
                coverImage,
                subtitle,
                categoryLink,
              }) => (
                <ArticleCard
                  key={recordId}
                  recordId={recordId}
                  date={updatedAt}
                  category={categoryLink}
                  cardImg={coverImage.gatsbyImageData}
                  cardImgMobile={coverImage.squaredImage}
                  altImg={title}
                  title={title}
                  excerpt={subtitle}
                />
              )
            )}
          </SectionGridThreeColsGallery>
        </Section>
    )
  );
};

const Section = styled.section`
  background-color: white;
`;

export default Services;
