import * as React from 'react';
import styled from 'styled-components';
import PhotoAlbum from 'react-photo-album';
// import optional lightbox plugins
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";


const Gallery = (props) => {
  const [index, setIndex] = React.useState(-1);
  return (
    <Wrapper>
      <Container>
        <Heading>{props.title}</Heading>
        <PhotoAlbum
          layout="rows"
          spacing={1}
          photos={props.photos}
          layoutOptions={{ spacing: 0, padding: 0, columns: 2 }}
          rowConstraints={{ maxPhotos: 5 }}
          onClick={({ index: current }) => setIndex(current)}
        />
        <Lightbox
                slides={props.photos}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                plugins={[Zoom]}
            />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
  background-color: white;
`;

const Container = styled.div`
  max-width: var(--globalContainer);
  margin: auto;
`;

const Heading = styled.h3`
  font-size: var(--headingL);
  justify-self: center;
  justify-content: center;
  text-align: center;
  padding: var(--globalPaddingLr) 0;
`;

export default Gallery;
