import { graphql } from 'gatsby';
import React, { Suspense } from "react";

import { PageWrapper } from '../components/Layout/PageWrapper';
import { Hero } from '../components/Layout/Hero';
import { HomepageHero } from '../components/sections';
//import Gallery from '../components/gallery';
import SellingPoints from '../components/selling-points';
import Services from '../components/services';
import Contacts from '../components/contacts';

import BackgroundVideo from '../components/background-video';
import {
  ParallaxBanner,
  ParallaxBannerLayer,
} from 'react-scroll-parallax';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

const Gallery = React.lazy(() => import('../components/gallery'))

const LL1Container = styled.div`
  position: relative;
  margin-bottom: -38rem;
`;

// Text content offset
const LayoutLayer1 = styled.div`
  position: relative;

  // Galaxy Fold
  @media (min-width: 280px) {
    top: calc(-38rem * 1.3);
  }

  // iPhone 4/SE, Galaxy S5
  @media (min-width: 320px) and (max-height: 640px) {
    top: calc(-38rem * 1.45);
  }

  // iPhone 12 Mini
  @media (min-width: 375px) and (max-height: 624px) {
    top: calc(-38rem * 1.4);
  }

  // iPhone 6/7/8 Plus
  @media (min-width: 400px) and (min-height: 600px) {
    top: calc(-38rem * 1.25);
  }

  // Galaxy A51/71
  @media (min-width: 400px) and (min-height: 900px) {
    top: calc(-38rem * 1.2);
  }

  // iPhone 5/SE Horizontal
  @media (min-width: 568px) and (max-height: 320px) {
    top: calc(-38rem * 1.78);
  }

  // Galaxy Note 3
  @media (min-width: 640px) and (max-height: 360px) {
    top: calc(-38rem * 1.75);
  }

  // Galaxy Fold
  @media (min-width: 653px) and (max-height: 280px) {
    top: calc(-38rem * 1.78);
  }

  // iPhone 6/7/8 Plus
  @media (min-width: 736px) and (max-height: 414px) {
    top: calc(-38rem * 1.60);
  }

  // iPad mini
  @media (min-width: 768px) and (min-height: 1024px) {
    top: calc(-38rem * 1.1);
  }

  // Galaxy S8+
  @media (min-width: 740px) and (min-height: 360px) {
    top: calc(-38rem * 1.7);
  }

  // iPhone 12 Pro Horizontal
  @media (min-width: 844px) and (min-height: 390px) {
    top: calc(-38rem * 1.65); 
  }

  // iPhone XR Horizontal
  @media (min-width: 896px) and (min-height: 414px) {
    top: calc(-38rem * 1.55); 
  }

  // Galaxy S20 Ultra
  @media (min-width: 915px) and (min-height: 412px) {
    top: calc(-38rem * 1.55); 
  }

  // Surface Pro 7 Vertical
  @media (min-width: 900px) and (min-height: 1368px) {
    top: calc(-38rem * 0.9);
  }

   // Galaxy A51/71
  @media (min-width: 914px) and (min-height: 412px) {
    top: calc(-38rem * 1.56);
  }

  // iPad Pro
  @media (min-width: 1024px) and (max-height: 1366px) {
    top: calc(-38rem * 1);
  }

  // Mac 14'
  @media (min-width: 1147px) and (min-height: 635px) {
    top: calc(-38rem * 1.23);
  }

  // Smaller monitors and some TVs
  @media (min-width: 1280px) and (min-height: 720px) {
    top: calc(-38rem * 1.6);
  }

  // Some Windows laptops 
  @media (min-width: 1281px) and (max-width: 1536px) {
    top: calc(-38rem * 1.6);
  }

  // iPad Pro, Surface Pro 7 Horizontal
  @media (min-width: 1366px) and (min-height: 900px) {
    top: calc(-38rem * 1.4);
  }

  // 4K
  @media (min-width: 1921px) and (max-width: 3840px) {
    top: calc(-38rem * 1);
  }
`;


// CTA overlap
const LayoutLayer2 = styled.div`
  background-color: white;
  height: 100%;
  position: sticky;
  bottom: -26rem; 

  // Galaxy Fold
  @media (min-width: 280px) {
    bottom: -28rem; 
  }

  // iPhone 5/SE, Galaxy S5 
  @media (min-width: 320px) and (max-height: 640px) {
    bottom: -30rem; 
  }

  // Galaxy S3
  @media (min-width: 360px) and (max-height: 640px) {
    bottom: -26rem; 
  }

  // iPhone 12 Mini
  @media (min-width: 375px) and (max-height: 741px) {
    bottom: -29.5rem; 
  }

  // iPhone 12 Pro
  @media (min-width: 390px) and (max-height: 844px) {
    bottom: -19rem; 
  }

  // iPhone XR Galaxy A51/71
  @media (min-width: 400px) and (max-height: 900px) {
    bottom: -21rem; 
  }

  // iPhone 6/7/8 Plus
  @media (min-width: 414px) and (max-height: 738px) {
    bottom: -30rem;  
  }

  // iPhone 5/SE Horizontal
  @media (min-width: 568px) and (max-height: 320px) {
    bottom: -30.5rem;
  }

  // iPhone 6/7/8 Plus
  @media (min-width: 736px) and (max-height: 414px) {
    bottom: -31rem; 
  }

  // iPad Mini
  @media (min-width: 768px) and (max-height: 1024px) {
    bottom: 0rem; 
  }

  // iPad Air
  @media (min-width: 820px) and (max-height: 1180px) {
    bottom: 0rem; 
  }

  // iPhone XR/12 Pro
  @media (min-width: 844px) and (max-height: 414px) {
    bottom: -14.5rem; 
  }

  // Surface Pro 7
  @media (min-width: 900px) and (max-height: 1368px) {
    bottom: 0rem; 
  }

  // Galaxy A51/71 Horizontal
  @media (min-width: 914px) and (max-height: 412px) {
    bottom: -13.5rem;
  }

  // iPad Pro
  @media (min-width: 1024px) and (max-height: 1366px) {
    bottom: 0rem; 
  }

  // Mac 14'
  @media (min-width: 1147px) and (min-height: 635px) {
    bottom: -14rem; 
  }

  // Older square monitors
  @media (min-width: 1280px) and (max-height: 1024px) {
    bottom: 0rem;
  }

   // iPad Pro Horizontal
  @media (min-width: 1366px) and (max-height: 1024px) {
    bottom: 0rem;
  }

   // Surface Pro 7 Horizontal
  @media (min-width: 1366px) and (max-height: 900px) {
    bottom: -2rem;
  }

  // 4K
  @media (min-width: 1921px) and (max-width: 3840px) {
    bottom: 0rem;
  }
`;

const StickyButton = styled.div`
  bottom: 1rem;
  height: 1rem;
  width: 100%;

  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;

  padding: 2px;
  margin: 0 auto 2rem;
  background-color: white;
`;

const HomepageTemplate = ({
  data: {
    datoCmsHomepage: {
      seo,
      hero: [
        {
          heroAlt,
          heroTitle,
          heroSubtitle,
          heroCta: [{ ctaText, ctaValue, ctaType }],
        },
      ],
      content,
      features,
      galleryTitle,
      gallery,
      featuredPostsTitle,
    },
    datoCmsBlogRoot: { id },
    allBlogPosts: { postNodes },
    datoCmsMiscTextString: { seeAllPosts },
  },
  pageContext,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });
  const rem = parseInt(getComputedStyle(document.documentElement).fontSize);

  const imagesList = gallery.map( item => {
    item.src = item.sizes.src
    return item
  })

  return (
    <PageWrapper
      pageData={pageContext}
      seoTitle={seo?.seoTitle}
      seoDescription={seo?.seoDescription}
      seoImage={seo?.seoImage?.seoImageUrl}
    >
      <ParallaxBanner
        style={{
          aspectRatio: '2 / 1',
          height: `${rem * 4}rem`,
          margin: '0 auto',
          maxWidth: '100%',
          position: 'sticky',
          top: isMobile ? '7rem' : '4.2rem',
          zIndex: '-1',
        }}
      >
        <ParallaxBannerLayer expanded={false} translateY={[0, 0]}>
          <BackgroundVideo />
        </ParallaxBannerLayer>
        <ParallaxBannerLayer>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
          />
        </ParallaxBannerLayer>
      </ParallaxBanner>
      <LL1Container>
        <LayoutLayer1>
          <Hero
            caption={heroAlt}
            title={heroTitle}
            subtitle={heroSubtitle}
          />

          <SellingPoints features={features}/>
        </LayoutLayer1>
      </LL1Container>
      <LayoutLayer2>
        <StickyButton>
          <a className="classicButton" href={`tel:${ctaValue}`}>
            {ctaText}
          </a>
        </StickyButton>
        <Contacts/>
      </LayoutLayer2>

      <HomepageHero {...content[1]} />
      <Services postNodes={postNodes} featuredPostsTitle={featuredPostsTitle}/>
      <HomepageHero {...content[0]} />
      <Suspense>
        <Gallery photos={imagesList} title={galleryTitle}/>
      </Suspense>
    </PageWrapper>
  );
};

export default HomepageTemplate;

export const query = graphql`
  query HomepageQuery($locale: String!) {
    datoCmsHomepage(locale: { eq: $locale }) {
      locale
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
      hero {
        heroAlt
        heroTitle
        heroSubtitle
        heroCta {
          ctaText
          ctaValue
          ctaType
        }
      }
      content {
        ...HomepageHeroContent
      }
      features {
        id: originalId
        title
        description
      }
      galleryTitle
      gallery {
        width
        height
        sizes {
          src
        }
      }
      featuredPostsTitle
    }
    datoCmsBlogRoot(locale: { eq: $locale }) {
      locale
      id: originalId
    }
    allBlogPosts: allDatoCmsBlogPost(
      filter: {
        locale: { eq: $locale }
        featuredInHomepage: { eq: true }
        noTranslate: { ne: true }
        categoryLink: { noTranslate: { ne: true } }
      }
      sort: { order: ASC, fields: meta___updatedAt }
    ) {
      postNodes: nodes {
        locale
        id: originalId
        meta {
          updatedAt
        }
        categoryLink {
          title
        }
        coverImage {
          gatsbyImageData(forceBlurhash: false)
          squaredImage: gatsbyImageData(imgixParams: { ar: "1", fit: "crop" })
        }
        subtitle
        title
      }
    }
    datoCmsMiscTextString(locale: { eq: $locale }) {
      locale
      seeAllPosts
    }
  }
`;
